import LayoutAdmin from "@/layouts/LayoutAdmin";
import myTable from "./table";

export default {
  data() {
    return {
      kata_kunci: null,
      pilih_cif: null,
      search: "",
      loadingTb: false,
      listCif: [],
      tbData: [],
      getData: [],
      setId: null,
      listRegister: [],
      listProject: [],
      listEvent: [],
      list_usersTypes: [],
      list_userGroup: [],
      listBalai: [],
      porsi: 0,
      isShow: false,
      tableDetail: [],
      setDefault: null,
      projectId: null,
      satkerId: null,
      listBool: [
        {
          text: "Ya",
          value: true,
        },
        {
          text: "Tidak",
          value: false,
        },
      ],
      tanggalAgreement: "",
      tanggalEfektif: "",
      tanggalProjectCompletion: "",
      tanggalLoanClosing: "",
      realisasiKumulatifSebelum: "",
      nilaiPjmRph: "",
    };
  },
  created() {
    this.$emit(`update:layout`, LayoutAdmin);
  },
  mounted() {
    this.getTable();
    this.G_GetRef("balai").then((data) => {
      this.listBalai = data;
    });

    this.G_GetRef("proyek").then((data) => {
      this.listProject = data;
    });

    this.G_GetRef("satker/type").then((data) => {
      this.listType = data;
    });
  },
  components: {
    myTable,
  },
  methods: {
    getTable() {
      this.isShow = true;
      this.loadingTb = true;
      let filter = "";
      // if (this.$refs.filter_event.getValue()) {
      //   filter = '/'+this.$refs.filter_event.getValue()
      // }

      this.G_GetAny("register" + filter).then((res) => {
        this.loadingTb = false;
        this.tbData = res.data.content;
      });
    },

    edit(id) {
      console.log(id);
      this.$refs.modal.open("Edit Data Register", 600);
      this.setId = id;

      this.G_GetAny("register/" + id).then((res) => {
        this.getData = res.data;

        this.$nextTick(() => {
          this.$refs.projekId.setValue(res.data.projekId);
          this.$refs.isPln.setValue(res.data.isPln);
          this.$refs.isSbsn.setValue(res.data.isSbsn);
          this.$refs.isRm.setValue(res.data.isRm);

          if (res.data.tanggalAgreement) {
            this.tanggalAgreement = res.data.tanggalAgreement;
          } else {
            this.tanggalAgreement = "";
          }

          if (res.data.tanggalEfektif) {
            this.tanggalEfektif = res.data.tanggalEfektif;
          } else {
            this.tanggalEfektif = "";
          }

          if (res.data.tanggalProjectCompletion) {
            this.tanggalProjectCompletion = res.data.tanggalProjectCompletion;
          } else {
            this.tanggalProjectCompletion = "";
          }

          if (res.data.tanggalLoanClosing) {
            this.tanggalLoanClosing = res.data.tanggalLoanClosing;
          } else {
            this.tanggalLoanClosing = "";
          }

          if (res.data.realisasiKumulatifSebelum) {
            this.realisasiKumulatifSebelum = res.data.realisasiKumulatifSebelum;
          } else {
            this.realisasiKumulatifSebelum = "";
          }

          if (res.data.nilaiPjmRph) {
            this.nilaiPjmRph = res.data.nilaiPjmRph;
          } else {
            this.nilaiPjmRph = "";
          }
        });
      });
    },
    add() {
      if (this.$refs.form) {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
      this.setId = null;
      this.getData = [];
      this.$refs.modal.open("Tambah Data Register", 600);
    },
    formatDate(dateString) {
      var allDate = dateString.split(" ");
      var thisDate = allDate[0].split("-");
      var thisTime = allDate[1].split(":");
      var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("-");

      var suffix = thisTime[0] >= 12 ? "PM" : "AM";
      var hour = thisTime[0] > 12 ? thisTime[0] - 12 : thisTime[0];
      var hour = hour < 10 ? "0" + hour : hour;
      var min = thisTime[1];
      var sec = thisTime[2];
      var newTime = hour + ":" + min + suffix;

      return newDate;
    },
    store() {
      let myForm = document.getElementById("formData");
      let formData = new FormData(myForm);
      formData.append("projekId", this.$refs.projekId.getValue());
      formData.append("isPln", this.$refs.isPln.getValue());
      formData.append("isSbsn", this.$refs.isSbsn.getValue());
      formData.append("isRm", this.$refs.isRm.getValue());
      formData.append(
        "realisasiKumulatifSebelum",
        this.realisasiKumulatifSebelum
      );
      formData.append("nilaiPjmRph", this.nilaiPjmRph);

      if (this.tanggalAgreement) {
        var mydate = new Date(this.tanggalAgreement);
        var str = mydate.toISOString().slice(0, 10);
        formData.append("tanggalAgreement", str);
      }

      if (this.tanggalEfektif) {
        var mydate = new Date(this.tanggalEfektif);
        var str = mydate.toISOString().slice(0, 10);
        formData.append("tanggalEfektif", str);
      }

      if (this.tanggalLoanClosing) {
        var mydate = new Date(this.tanggalLoanClosing);
        var str = mydate.toISOString().slice(0, 10);
        formData.append("tanggalLoanClosing", str);
      }

      if (this.tanggalProjectCompletion) {
        var mydate = new Date(this.tanggalProjectCompletion);
        var str = mydate.toISOString().slice(0, 10);
        formData.append("tanggalProjectCompletion", str);
      }

      formData.append("isTemp", false);

      var object = {};
      formData.forEach((value, key) => (object[key] = value));

      if (this.$refs.form.validate()) {
        this.$refs.modal.loading();

        if (this.setId) {
          this.G_PutAnyCustom("register", object)
            .then((res) => {
              if (!res.status) {
                this.getTable();
                this.$snotify.success("Berhasil Mengubah Data");
                this.$refs.modal.close();
              } else {
                this.$snotify.warning("Terjadi Kesalahan");
              }
            })
            .finally(() => {
              this.$refs.modal.loadingEnd();
            });
        } else {
          console.log("add");
          this.G_PostAnyCustom("register", object)
            .then((res) => {
              if (!res.status) {
                this.getTable();
                this.$snotify.success("Berhasil Menyimpan Data");
                this.$refs.modal.close();
              } else {
                this.$snotify.warning("Terjadi Kesalahan");
              }
            })
            .finally(() => {
              this.$refs.modal.loadingEnd();
            });
        }
      } else {
        this.$snotify.info("Lengkapi Data Terlebih Dahulu");
      }
    },
    async hapus(id) {
      let judul = "Hapus Data";
      let subJudul = "Yakin Akan Hapus Data Ini?";

      if (await this.$refs.confirm.open(judul, subJudul)) {
        this.$refs.confirm.loading();
        this.G_DeleteAny("register/" + id)
          .then((res) => {
            console.log(res);
            if (res) {
              this.$refs.confirm.close();
              this.getTable();
              this.$snotify.success("Berhasil Hapus Data");
            } else {
              this.$snotify.warning(res.message);
            }
          })
          .finally(() => {
            this.$refs.confirm.loadingEnd();
          });
      }
    },
    detail(projectId) {
      // this.projectId = projectId
      // this.G_GetAny(`/porsiLoan/projek/${this.$refs.filter_event.getValue()}/${projectId}`).then(res => {
      //   console.log(res);
      //   this.tableDetail = res.data.content
      //   this.$refs.modalDetail.open("Detail Data KPPN per Satker", 1000)
      // })
    },
  },
};
